import { getLang } from "../../js/utils/functions/index";

export const UNSUPPORTED_URL = "unsupported-browser";
const auxLang = I18n?.locale || I18n?.defaultLocale || "es";

export const redirectUnsupportedBrowser = ({ lang = auxLang }) => {
  if (!document || !window) {
    return;
  }

  if (window.location?.pathname?.indexOf(UNSUPPORTED_URL) > -1) return;

  const { userAgent } = window.navigator;
  const hasIEDocumentMode = typeof document.documentMode !== "undefined";
  const hasIEUserAgent = /MSIE|Trident/.test(userAgent);

  const isIE = hasIEDocumentMode || hasIEUserAgent;

  if (isIE) {
    window.location?.assign(`/${lang}/${UNSUPPORTED_URL}`);
  }
};

redirectUnsupportedBrowser({ lang: getLang });
